import { environment } from '../../environments/environment';


export class Config {
    public static getControllerUrl(controllerName: string, actionName?: string) {
        return this.getApiUrl() + '/' + controllerName + (actionName ? '/' + actionName : '');
    }
    public static getApiUrl() {
        return this.getServerUrl() + '/api';
    }
    public static getServerUrl() {
        
     //return environment.production ? '' : 'http://localhost:46407';
       //return 'https://gfdeskdev.fleetdesks.com';
       return 'https://bikebox.fleetdesks.com';
       // 'https://gfdeskdev.fleetdesks.com';
    }
   // https://gfdeskdev.fleetdesks.com/
  // http://localhost:46407
    public static dashifyCNIC(cnic: string) {
        return cnic[0] +
            cnic[1] +
            cnic[2] +
            cnic[4] +
            '-' +
            cnic[5] +
            cnic[6] +
            cnic[7] +
            cnic[8] +
            cnic[9] +
            cnic[10] +
            cnic[11] +
            '-' +
            cnic[12];
    }
}
